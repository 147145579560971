.financing-card {
    border: 1px solid #dee2e6;
    border-radius: 5px;
    padding: 20px;
    // height: 377px;

    .image-preview {
        height: 130px !important;
    }
}

.bordergreen{
    border: 4px solid green;
}
