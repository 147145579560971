.panel {
    z-index: 2;
    position: absolute;
    
    background-color: white;
    width:250px;
    border: 1px solid black;
    border-radius:5px;

    @media (max-width: 440px) {
        left:15px;
        top:75px;
    }

    @media (min-width: 440px) {
        right:20px;
        top:75px;
    }
}

.monthcell {
    font-size: 12px;
    font-weight: 800;
    text-decoration: underline;
}

.monthSelected {
    color: $blue !important;
    font-weight: bold;
}