button:active {
  opacity: 0.6;
}

button {
    opacity: 1;
    border-radius: 5px;
    height: 40px;
}

button.btn-clickable-pseudo-disabled {
    background: #F1F1F1 !important;
    background-color: #F1F1F1 !important;
    border-color: #F1F1F1 !important;
    color: #989898 !important;
}

%btn-default {
  align-items: center;
  background-color: $blue-transparent;
  border: 1px solid transparent;
  box-shadow: none;
  color: $blue;
  display: inline-flex;
  font-size: 0.8rem;
  font-weight: 500 !important;
  justify-content: center;
  letter-spacing: 0.5px;
  outline: none;
  position: relative;
  // transition: all 150ms ease-in-out;

  &:disabled,
  .btn-disabled,
  .btn-primary:disabled,
  [disabled] {
    background: #f1f1f1 !important;
    background-color: #f1f1f1 !important;
    border-color: #f1f1f1 !important;
    color: #989898 !important;
  }

  &:active,
  &:focus {
    box-shadow: none !important;
  }
}

.btn {
  background-color: $blue-transparent !important;
  color: $blue !important;
  @extend %btn-default;
  .mdi {
    display: inline-block;
    font-size: 1.5em;
    margin-right: 0.25em;
    vertical-align: middle;
  }

  &.btn-outline {
    border-color: $blue !important;
    background-color: transparent !important;
  }

  &.btn-danger {
    color: #dc3545 !important;
    background-color: #dc3545 !important;
    border-color: transparent !important;
  }

  &.btn-primary {
    @extend %btn-default;
    background-color: $blue !important;
    color: $white !important;
    &.btn-outline {
      border-color: $blue;
    }
    &:disabled,
    .btn-disabled,
    .btn-primary:disabled,
    [disabled] {
      background: #f1f1f1 !important;
      background-color: #f1f1f1 !important;
      border-color: #f1f1f1 !important;
      color: #989898 !important;
    }
  }

  &.btn-secondary {
    @extend %btn-default;
    color: $secondary !important;

    &.btn-outline {
      border-color: $secondary;
    }

    &--active {
      background-color: lighten($secondary, 5%) !important;
      color: $white !important;
    }
  }

  &.btn-link {
    @extend %btn-default;
    background: $blue-transparent !important;
    box-shadow: none;
    color: $blue !important;
    font-size: 16px;
    padding: 0 0.75em;

    &.btn-error {
      &:hover {
        color: $secondary !important;
      }
    }

    &:hover {
      text-decoration: none;
    }

    &.btn-outline {
      border-color: $blue-transparent !important;
    }

    &:hover {
      background: $blue-transparent !important;
      color: $blue !important;
      text-decoration: none;
    }

    .mdi {
      font-size: 1.5em;
    }
  }

  &.btn-grey {
    background: transparent !important;
    color: $placeholder-color !important;
    font-weight: normal;

    &.btn-outline {
      border-color: $placeholder-color !important;
    }

    &:hover,
    &:active {
      background: transparent !important;
      color: $placeholder-color !important;
    }
  }

  &.btn-transparent {
    background-color: transparent !important;
    &:hover {
      background-color: transparent !important;
    }
  }
}
.btn-group {
  .btn {
    width: 100%;
  }
}

.btn-group.row [class*='col'] {
  padding: 8px;
}
