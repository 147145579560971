// Components

@import '../js/common/components/dropdown/style';
@import '../js/common/components/empty-state/style';
@import '../js/common/components/image-preview/style';
@import '../js/common/components/header/style';
@import '../js/common/components/sidebar/style';
@import '../js/common/components/financing-slider/style';
@import '../js/common/components/loading/style';
@import '../js/common/components/datatable/style';
@import '../js/common/components/inputs/style';
@import '../js/common/components/pagination/style';
@import '../js/common/components/modal/style';
@import '../js/common/components/colored-label/style';
@import '../js/common/components/cart-component/style';
@import '../js/common/components/dropzone/style';
@import '../js/common/components/stepper-component/style';
@import '../js/common/components/tabs/style';
@import '../js/common/components/status-progress/style';
@import '../js/common/components/financing-card/style';
@import '../js/common/components/line-chart/style';

/* Position and sizing of burger button */
.bm-burger-button {
    position: absolute;
    width: 30px;
    height: 23px;
    top: 30px;
    left: initial;
}

.bm-menu-wrap {
    top: 0px;
    left: 0px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: white;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #cac1c1;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
    left: 8px;
    right: 0px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
    height: 20px;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
    height: 100%;
}

/* General sidebar styles */
.bm-menu {
    background: white;
    font-size: 1.15em;

    a,
    span {
        text-decoration: none;
    }

    .user-image {
        margin: auto !important;
        height: 7rem;
        width: 7rem;
        background-size: cover;
        background-position: center center;
        border-radius: 50%;
    }
}

a.bm-item:hover {
    background: $blue-light !important;
    color: white !important;
}

.bm-item:focus {
    outline: none;

}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
}

/* Individual item */
.bm-item {
    &--active {
        background: $blue-light;
        color: white;
    }

    display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}
