.search-input {
    background-color: #f7f7f7;
    position: relative;

    input {
        padding-left: 3rem;
        height: 44px;
        background-color: $white;
    }

    .form-control {
        height: 100%;
        border-radius: 5px;
        box-shadow: $shadow-style-card;
        font-size: 16px;
        width: 100%;
    }

    &__icon {
        color: #989898;
        font-size: 1.3rem;
        position: absolute;
        left: 16px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
    }
}
