// Checkbox
.checkbox {
  $parent: &;
  margin: 0;
  width: 100%;

  &__text {
    color: #383838;
    padding-left: 8px;
    margin-top: 3.5px;
  }

  & &__input {
    opacity: 0;
    position: absolute;
    z-index: -1;
    width: 0;
    height: 0;
  }
  & &__label {
    align-items: center;
    background-color: transparent;
    border: 0;
    color: $blue-dark;
    display: flex;
    font-size: 14px;
    position: relative;
    text-align: left;
    width: 100%;
    &[disabled],
    &[readonly] {
      background-color: transparent;
      opacity: 0.6;
      cursor: not-allowed;
    }
  }
  & &__check {
    background-color: transparent;
    border-radius: 50%;
    border: 2px solid $gray;
    cursor: pointer;
    display: block;
    height: 20px;
    margin: 0;
    position: relative;
    width: 20px;
    min-width: 20px;

    &.checkbox--squared {
      border-radius: 2px;
    }

    &::before {
      background-color: transparent;
      border: 0;
      border-bottom: 2px solid white;
      border-right: 2px solid white;
      content: " ";
      display: block;
      height: 13px;
      opacity: 0;
      left: 4px;
      margin-top: -8px;
      position: absolute;
      top: 50%;
      transform: rotate(0deg);
      width: 7px;
    }
  }
  &--indeterminate {
    #{$parent}__label {
      color: $blue;
    }
    #{$parent}__check {
      background-color: $blue;
      border-color: $blue;
      color: white;
      &::before {
        transform: rotate(90deg);
        opacity: 1;
        border-bottom: 0px !important;
      }
    }
  }
  &--checked {
    #{$parent}__label {
      color: $blue;
    }
    #{$parent}__check {
      background-color: $blue;
      border-color: $blue;
      color: white;
      &::before {
        transform: rotate(40deg);
        opacity: 1;
      }
    }
  }
  &--label-first {
    #{$parent}__check {
      left: auto;
      right: 17px;
      top: 15px;
    }
    #{$parent}__label {
      padding: 1rem;
    }
  }
  &--blue {
    #{$parent}__label {
      color: $blue;
    }
    #{$parent}__check {
      border: 2px solid $blue-dark;
    }
  }
  &--left-margin {
    margin-left: -14px;
  }
  &--no-rounded {
    min-width: 55px;
    #{$parent}__label {
      color: $blue;
    }
    #{$parent}__check {
      border: 1px solid $gray;
      border-radius: 3px;
    }
    &.checkbox--checked {
      #{$parent}__check {
        background-color: $blue-dark;
        border-color: $blue-dark;
        &::before {
          display: none;
        }
      }
    }
  }
  &--no-label {
    display: inline-block;
    height: 24px;
    width: 24px;
    overflow: hidden;
    #{$parent}__label {
      height: 24px;
    }
    #{$parent}__check {
      flex: 1 0 20px;
      height: 20px;
      width: 20px;
    }
  }
}
