.empty-state {
  background-color: transparent;
  &:not(.empty-state--small) {
    padding-top: 3rem;
  }

  &.empty-state--small {
    .empty-state__image {
      max-width: px-to-rem(48);
    }
  }

  .empty-state__image {
    display: inline-block;
    margin-bottom: 1rem;
    max-width: 156px;
  }

  .empty-state__headline {
    font-size: px-to-rem(20);
    font-weight: 500;
    line-height: 26px;
    color: $gray;
    margin-bottom: 1.25rem;
    strong {
      color: rgba(56, 56, 56, 0.87);
    }
  }
}
