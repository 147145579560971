.switch-presentation {
  align-items: center;
  display: flex;
  margin: 0;
  padding: 0;

  &__item {
    display: inline-block;
    margin: 0 16px;

    &:last-child {
      margin-right: 0;
    }
  }
  &__link {
    color: $gray;
    display: block;
    padding: 5px;
    &:hover,
    &:active,
    &:focus {
      color: $gray;
    }
    .mdi {
      font-size: 1.5rem;
      line-height: 30px;
    }
  }
  &__link--active {
    background-color: $primary;
    border-radius: 10px;
    color: $blue;
    &:hover,
    &:active,
    &:focus {
      color: $blue;
    }
  }
  &__tab--active {
    color: $blue;
    &:hover,
    &:active,
    &:focus {
      color: $blue;
    }
  }
}

.edit-financing-modal {
    @media (min-width: 601px) {
        width: 60% !important;
    }
}

.label-in-card{
  font-weight: bold;
  font-size: 0.8em;

}

.selectcompare{
  z-index: 3;
}

.filter-active{
  border-color: #fd8139;
  font-weight: bold;
  font-style: italic;
}

.filterDrop{
  &__control{
    border-color: #fd8139 !important;
  }  
  /* &__single-value {
      color: #fd8139 !important;
      font-weight: bold;
      font-style: italic;

  } */
}

.month-selected{
  &__single-value{
    color: $blue !important;
  }
}