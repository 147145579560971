.react-tabs {
  $parent: &;
  &__tab-list {
    clear: both;
    display: table;
    margin: 0 0 30px;
    border: 1px solid $gray-light;
    padding: 5px;
    table-layout: fixed;
    width: 100%;
  }
  &__tab {
    // border-bottom: 1px solid $gray-light;
    color: $blue-dark;
    cursor: pointer;
    display: table-cell;
    list-style: none;
    padding: 13px 13px 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
    text-align: center;
    width: auto;
    &--selected {
      background: $primary;
      color: $white;
      font-weight: bold;
      padding: 13px 13px 15px;
    }
  }
  &__tab-panel {
    display: none;
    &--selected {
      display: block;
    }
    &--disabled {
      color: rgba(0, 0, 0, 0.24);
      cursor: default;
    }
  } // Modifier
  &--2-tabs {
    #{$parent}__tab-list {
      display: block;
    }
    #{$parent}__tab {
      display: inline-block;
      width: 50%;
    }
  }
}

.contacts-tabs {
  .react-tabs__tab-list {
    margin-bottom: 0px;
    width: 100%;
  }

  .react-tabs__tab {
    background: white;
  }
}

.users-tabs {
  .react-tabs__tab {
    background: white;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .react-tabs__tab-list {
    margin-bottom: 0px;
  }
}


@media (max-width: 440px) {
  .react-tabs {
      font-size: 9px;
  }
}