.css-g1d714-ValueContainer, .css-1hwfws3 {
    height: 44px;
    border-radius: 5px;
}

.css-yk16xz-control {
    height: 44px;
    border-radius: 5px;
    box-shadow: $shadow-style-card;
    font-size: inherit;
    width: 100%;
}

.has-error {
    .css-yk16xz-control,
    .css-yk16xz-control:focus {
        border: 2px solid $red !important;
    }
}

