.colored-label {
    border-radius: 5px;
    padding: 2px 15px;
    width: fit-content;

    i {
        margin-right: 5px;
    }
    span {
        vertical-align: top;
    }
}
