
.Planstable {
    white-space: nowrap;
    margin: 0;
    border-collapse: separate;
    border-spacing: 0;
    table-layout: fixed;
    border: 1px solid #d2d2d2;
  }
.Planstable thead tr th {
    width:'150px';
    margin:'10px 10px 10px 10px';
    overflow-wrap:'break-word';
    white-space:'pre-wrap';
}
.Planstable td,
.Planstable th {
    border-bottom: 1px solid #d2d2d2;
    box-shadow:'0 1px 5px 0 rgba(0, 0, 0, 0.15)';
}
.Planstable thead th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
    background: white;
}

.Planstable thead tr:first-child {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
    background: white;
}
.Planstable tbody tr {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
    background: white;
} 

.Planstable td {
    background: #fff;
    //padding: 4px 5px;
    text-align: center;
}
  
.Planstable tbody th {
    text-align: left;
    position: relative;
}
 .Planstable thead th:first-child {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 2;
    //width: 60px;
    word-wrap: break-word;
    border-left: 2px solid #d2d2d2;
} 
.Planstable tbody td:first-child {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 2;
    //width: 60px;
    word-wrap: break-word;
    border-left: 2px solid #d2d2d2;
} 



.Planstable tbody th {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    background: white;
    z-index: 1;
    border-left: 2px solid #d2d2d2;
}

  [role="region"][aria-labelledby][tabindex] {
    width: 100%;
    max-height: 400px;
    overflow: auto;
  }
  [role="region"][aria-labelledby][tabindex]:focus {
    box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);
    outline: 0;
  }