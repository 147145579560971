.image-preview {
    background-color: transparent;
    background-position: center center;
    background-size: cover;
    display: inline-block;
    border-radius: 4px;
    margin-right: 10px;
    &__xs {
      height: 50px;
      width: 50px;
    }
    &__sm {
      height: 70px;
      width: 70px;
    }
    &__md {
      height: 100px;
      width: 100px;
    }
    &__lg {
      height: 130px;
      width: 130px;
    }
    &__xl {
      height: 180px;
      width: 180px;
    }
  }
