.wait {
    cursor: wait !important;
    pointer-events: none !important;
}

/* ------------------------------------------------------------ */

/*  Floats */

/* ------------------------------------------------------------ */

.float-left {
    float: left !important;
}

.float-right {
    float: right !important;
}

.float-none {
    float: none !important;
}

.o-inherit {
    overflow: inherit !important;
}

.white-box {
    height: auto;
    background-color: #fff;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
}

.paragraph {
    line-height: 1.6;
    white-space: pre-wrap;
    word-break: break-word;
}

/* --------------------- */

/* Displays */

/* --------------------- */

.display--block {
    display: block;
}

.display--inline-block {
    display: inline-block;
}

.vertical-align--top {
    vertical-align: top;
}

.vertical-align--middle {
    vertical-align: middle;
}

.vertical-align--bottom {
    vertical-align: bottom;
}

.w-min {
    width: min-content;
}

.w-20 {
    width: 20%;
}

.w-35 {
    width: 35%;
}

.w-45 {
    width: 45%;
}

.w-55 {
    width: 55%;
}

.w-40 {
    width: 40%;
}

.w-60 {
    width: 60%;
}

.w-70 {
    width: 70%;
}

.w-30 {
    width: 30%;
}

/* --------------------- */

/* Margins and paddings */

/* --------------------- */

.right {
    right: 10px;
}

.left {
    left: 10px;
}

.left30 {
    left: 30px;
}

.mg0 {
    margin: 0 !important;
}

.mg1 {
    margin: 100px;
}

.mgG {
    margin: 10px;
}

.mgB {
    margin-bottom: 10px;
}

.mgT {
    margin-top: 10px;
}

.mgL {
    margin-left: 10px;
}

.mgL5 {
    margin-left: 50px;
}

.mgL4 {
    margin-left: 40px;
}


.mgR {
    margin-right: 10px;
}

.mgR0 {
    margin-right: 0px !important;
}

.mgL0 {
    margin-left: 0px !important;
}

.mgB0 {
    margin-bottom: 0 !important;
}

.invisible {
    visibility: hidden;
    pointer-events: none;
}

.mgB15 {
    margin-bottom: 15px;
}

.mgTm2 {
    margin-top: -20px;
}

.mgB2 {
    margin-bottom: 20px;
}

.mgB3 {
    margin-bottom: 30px;
}

.mgB4 {
    margin-bottom: 40px;
}

.mgB10 {
    margin-bottom: 100px;
}

.mgR2 {
    margin-right: 20px;
}

.mgR3 {
    margin-right: 30px;
}

.mgR0 {
    margin-right: 0px;
}

.mgL2 {
    margin-left: 20px;
}

.mgL3 {
    margin-left: 30px;
}

.mgL0 {
    margin-left: 0px;
}

.mgB5 {
    margin-bottom: 50px;
}

.mgB7 {
    margin-bottom: 70px;
}

.mgB45 {
    margin-bottom: 45px;
}

.mgT0 {
    margin-top: 0 !important;
}

.mgT15 {
    margin-top: 15px;
}

.mgT2 {
    margin-top: 20px;
}

.mgT3 {
    margin-top: 30px;
}

.mgT4 {
    margin-top: 40px;
}

.mgT5 {
    margin-top: 50px;
}

.pd0 {
    padding: 0 !important;
}

.pd1 {
    padding: 100px;
}

.pdG {
    padding: 10px;
}

.pd05 {
    padding: 5px;
}

.pd3 {
    padding: 30px !important;
}

.pd2 {
    padding: 20px !important;
}

.pd5 {
    padding: 50px !important;
}

.pd-md-5 {
    @media (min-width: 601px) {
        padding: 50px !important;
    }
    @media (max-width: 600px) {
        padding: 0px;
    }
}

.pdL7 {
    padding-left: 70px !important;
}

.pdL100 {
    padding-left: 100px !important;
}

.pdB {
    padding-bottom: 10px;
}

.pdT15 {
    padding-top: 15px;
}

.pdT {
    padding-top: 10px;
}

.pdL0 {
    padding-left: 0px !important;
}

.pdR0 {
    padding-right: 0px !important;
}

.pdL {
    padding-left: 10px;
}

.pdR {
    padding-right: 10px;
}

.pdR3 {
    padding-right: 30px;
}

.pdR5 {
    padding-right: 50px !important;
}

.pdB0 {
    padding-bottom: 0 !important;
}

.pdB15 {
    padding-bottom: 15px;
}

.pdB2 {
    padding-bottom: 20px;
}

.pdR2 {
    padding-right: 20px;
}

.pdL2 {
    padding-left: 20px;
}

.pdL3 {
    padding-left: 30px;
}

.pdL5 {
    padding-left: 50px;
}

.pdB5 {
    padding-bottom: 50px;
}

.pdB45 {
    padding-bottom: 45px;
}

.pdT0 {
    padding-top: 0 !important;
}

.pdT15 {
    padding-top: 15px;
}

.pdT2 {
    padding-top: 20px;
}

.pdT5 {
    padding-top: 50px;
}

.pdT7 {
    padding-top: 70px;
}

/* ------------------------------------------------------------ */

/*  Row variant */

/* ------------------------------------------------------------ */

.row--variant {
    margin-left: -7px;
    margin-right: -7px;

    div[class^='col-'],
    div[class*=' col-'] {
        padding-left: 7px;
        padding-right: 7px;
    }
}

/* ------------------------------------------------------------ */

/*  Miscellaneous */

/* ------------------------------------------------------------ */

.breakLine {
    white-space: pre-wrap;
}

.cursor-pointer {
    cursor: pointer;
}

.hoverable {
    &:hover {
        background-color: $gray-lighter !important;
        // color: white !important;
        border: 2px solid black !important;
    }
}

.selected {
    background-color: $white;
    // color: white !important;
    border: 2px solid $blue !important;

    .selected-header {
        background-color: $blue !important;
    }
}

.selected-left {
    background-color: $white;
    // color: white !important;
    border: 2px solid $blue !important;
    border-right-color: transparent !important;
    
}
.selected-right {
    background-color: $white;
    // color: white !important;
    border: 2px solid $blue !important;
    border-left-color: transparent !important;
    
}

.cursor-default {
    cursor: default;
    pointer-events: inherit
}

.no-border {
    border: 0 !important;
}

.height5 {
    height: 50px !important;
}

.h-500 {
    height: 500px !important;
}

.min-h-500 {
    min-height: 500px !important;
}

.min-h-200 {
    min-height: 200px !important;
}

.full-height {
    height: 100%;
}

.half-disabled {
    pointer-events: unset;
    opacity: 0.4;
}

.disabled {
    pointer-events: unset;
    opacity: 0.4;
    cursor: not-allowed;
}

.t50 {
    top: 50%;
}

.vertical--center {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.small-border {
    border: 1px solid $gray;
}

.small-border-bottom {
    border-bottom: 1px solid $gray;
}

.small-border-left {
    border-left: 1px solid $gray;
}

.small-border-right {
    border-right: 1px solid $gray;
}

@media (min-width: 768px) {
    .o-md-inherit {
        overflow: inherit !important;
    }

    .border-md-left {
        border-left: 1px solid $gray-light;
    }

    .border-md-right {
        border-right: 1px solid $gray-light;
    }

    .border-md-bottom {
        border-bottom: 1px solid $gray-light;
    }
    .border-md-top {
        border-top: 1px solid $gray-light;
    }
}

@media (max-width: 767px) {
    .border-sm-bottom {
        border-bottom: 1px solid $gray-light;
    }
}

.border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.16);
}

.border-top {
    border-top: 1px solid rgba(0, 0, 0, 0.16);
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.hr {
    border-top: 1px solid $blue-dark;
    margin-top: 30px;
    margin-bottom: 30px;

    &--variant {
        border-top: 1px solid $gray;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    &--smaller {
        border-top: 1px solid $gray-light;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    &--dashed {
        border-top-style: dashed;
    }
}

.bordered {
    border: 1px solid $gray-light;
    border-radius: 5px;
}

.bordered-blue {
    border: 1px solid $blue;
    border-radius: 5px;
}

.bordered-card {
    border: 1px solid $gray-light !important;
    border-left: 5px solid $gray-light !important;
}

.blocked {
    pointer-events: none;
}

.display--inline-flex {
    display: inline-flex !important;
}

.screen-reader-text {
    display: none;
}

.full-width {
    width: 100% !important;
}

.w-95 {
    width: 95%;
}

.no-action {
    pointer-events: none;
}

.align-center {
    margin: auto !important;
    width: 40%;

    &__w-60 {
        width: 60%;
    }
}

// Backgrounds
.darkgray-background {
    background-color: rgba(245, 245, 245, 1);
}

.gray-background {
    background-color: rgba(250, 250, 250, 1);
}

.gray-lighter-background {
    background-color: $gray-lighter;
}

.white-background {
    background-color: rgba(255, 255, 255, 1) !important;
}

.color-blue {
    color: $blue;
}

.color-primary {
    color: $primary;
}

.color-lightblue {
    color: rgb(108, 178, 235);
}

.color-gray {
    color: $gray;
}

.color-green {
    color: $green;
}

.color-yellow {
    color: $yellow;
}

.color-orange {
    color: $orange;
}

.important-information {
    background-color: #E1EAF7;
    color: #124B6F;
    border-radius: 5px;
    padding: 10px
}

.vr {
    border-left: 1px solid $gray-light;
    position: absolute;
}

.color-red {
    color: $red;
}

.color-green {
    color: $green;
}

.color-orange {
    color:#fd8139;
}

.fadeOut {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.5s, opacity 0.5s linear;
}

.fadeIn {
    display: block;
    visibility: visible;
    opacity: 1;
    transition: opacity 0.5s linear;
}

.fixed {
    position: fixed;
}

.sticky-below-header {
    position: -webkit-sticky;
    position: sticky;
    top: 75px;
}

.footer.row {
    margin-top: 25px;
    width: 100%;
}

.footer-fixed {
    margin-top: 25px;
    width: 100%;
    position: fixed;
    background-color: rgba(255, 255, 255, 1);
    bottom: 0px;
    z-index: 1000;
}

.fs-1-rem {
    font-size: 16px;
}

.modalLoading {
    margin-top: 20%;
    width: 40%;

    @media (max-width: 440px) {
        margin-top: 20%;
        width: 80%;
    }
}

.fsbold{
    font-weight: bold;
}

.tablePlan {
    width: 35%;
    background-color: white !important;
    @media (max-width: 440px) {
        width: 50%;
    }
}
.tableDataplan {
    width: 95%;
    background-color: white !important;
    @media (max-width: 440px) {
        width: 100%;
    }
}

.border-red {
    border-color: red !important;
}

.value{
    font-size: 18px;

    &-up{
        color: #03bb00;
    }
    &-down{
        color: rgb(216, 20, 20) !important;
    }
}

.fsize{
    &-14 {font-size: 14px;}
    &-12 {font-size: 12px;}
    &-16 {font-size: 16px;}
    &-15r {font-size: 1.5rem}
}

