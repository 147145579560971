// Pagination
.pagination {
  $parent: &;
  margin-top: 2rem;
  display: block;
  padding: 0;
  text-align: right;

  ul {
    margin: 0;
    padding: 0;
  }

  &__item {
    display: inline-block;
    margin: 0 0 0 6px;
  }

  a,
  #{$parent}__item__archor {
    background-color: transparent;
    border-radius: 2px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 2px 1px -1px rgba(0, 0, 0, 0.12),
      0 1px 3px 0 rgba(0, 0, 0, 0.2);
    color: #1FB0EE;
    display: block;
    font-size: 16px;
    font-weight: 300;
    outline: none;
    padding: 0.4em 0.8em;
  }

  &__item--active {
    #{$parent}__item__archor {
      background-color: #1FB0EE;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
      color: $white;
      pointer-events: none;
    }
  }

  &__item--break {
    color: $gray-dark;
  }

  #{$parent}__item__archor {
    cursor: pointer;
  }

  &__item--no-display {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }
}

.pagination-mobile, .pagination-tablet {
  margin-top: 16px;
  display: flex;
  justify-content: center;

  .pagination {

    &__item{
      background-color: white;

      a {
        min-width: 40px;
        min-height: 46px;

        display: flex !important;
        justify-content: center;
        align-items: center;

        font-size: 14px;
        line-height: 17px;
        letter-spacing: -1px;
      }

      &--no-display {
        opacity: 1;
        pointer-events: none;
        visibility: visible;
        background-color: inherit;

        a {
          color: rgba(0, 0, 0, 0.38) !important;
        }
      }

      &--break {
        background-color: inherit;
      }
    }
  }
}
