// Inputs

@import './checkbox/style.scss';
@import './search-input/style.scss';
@import './formsy-input-wrapper/style';
@import './select-input/style';
@import './password-input/style';
@import './radio-buttons/style';
@import './textarea/style';
@import './date-range-input/style';
@import './date-picker/style';



input {
    min-height: 40px;
    font-family: $font-family-sans-serif;
    border-radius: 5px;
}

input.react-toggle-screenreader-only {
    min-height: inherit !important;
}

[placeholder] {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.text-input__with-icon {
    color: #989898;
    font-size: 1.71428571rem;
    position: absolute;
    left: 10px;
    top: 57%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 2;
}

.text-input__with-sufix {
    font-size: 1.1428571rem;
    position: absolute;
    right: 20px;
    top: 47%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 2;
}

input.form-control.with-icon {
    padding-left: 40px !important;
}

::-webkit-input-placeholder {
    color: #aaa !important;
}

::-moz-placeholder {
    color: #aaa !important;
}

::-ms-placeholder {
    color: #aaa !important;
}

::placeholder {
    color: #aaa !important;
}
