.modal {
  &__overlay {
    // Position
    bottom: 0px;
    left: 0px;
    overflow: hidden;
    position: fixed;
    right: 0px;
    top: 0px;
    text-align: center;
    z-index: 1005; // 1 more than the header
    background-color: rgba(0, 0, 0, 0.45);
  }
  &__content {
    background: $gray-background-variant;
    border: 0;
    display: inline-block;
    border-radius: 5px;
    outline: none;
    margin-bottom: 20px;
    margin-top: 100px;
    max-height: 80%;
    overflow-x: auto;
    @media (max-width: 600px) {
      width: 96%;
    }
    @media (min-width: 601px) {
      width: 500px;
    }
    padding: 20px;
    text-align: left;
  }
  &--is-open {
    overflow: hidden;
  }
}

.modal--new-offer {
  width: 820px;
}
