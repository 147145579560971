// Header
.layout-header {
  align-items: center;
  padding: 15px 50px;
  background-color: $primary;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.12), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.2);
  color: $primary;
  display: flex;
  height: $header-height;
  left: 0;
  padding: 1.5rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999;

  &__title {
    margin: 0;
  }

  &__logo {
    background-image: url('/images/white_logo.svg');
    background-repeat: no-repeat;
    background-size: 173px 54px;
    display: block;
    height: 54px;
    width: 200px;
  }

  &__nav {
    align-items: center;
    display: flex;
    padding: 0;
    margin: 0;

    > li {
      margin: 0 1rem;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      > a {
        color: $white;
        display: block;

        > i {
            font-size: 1.5rem;
        }

        .label {
          font-size: 2.1428571rem;
        }

        &:hover {
          color: lighten($white, 10%);
          text-decoration: none;
        }
      }
    }

    > .divider {
      background-color: white;
      height: $header-height;
      margin: 0 1rem;
      opacity: 0.6;
      width: 1px;
    }
  }

  &__dropdown {
    margin: 0 !important;

    .dropdown__toggle {
      background-color: transparent;
      border: 0;
      color: $white;
      font-size: 16px;
      font-weight: 400;
      height: auto;
      width: 200px;
      line-height: 1;
      text-align: left;
      outline: 0;
      padding: 0;

      .mdi {
        font-size: 1.5rem;
        position: absolute;
        top: 4px;
        left: 90%;
        margin: 0;
      }
    }

    .dropdown__menu.default {
        min-width: 220px;
        padding: 8px 12px;
        left: auto;
        right: -20px;
    }

    .dropdown__menu.right-up {
        min-width: 220px;
        padding: 8px 12px;
        overflow: auto;
        left: 45% !important;
        top: -100% !important;
    }

    .dropdown__menu {
        background-color: $primary !important;
        text-align: left;
        border: 0px;
        a {
            color: $white;
            font-weight: $font-weight-semibold;
            text-align: center;
        }
    }
  }
}

.user-name {
  align-items: center;
  display: flex;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.5px;
}

// Header
.layout-header-mobile {
  align-items: center;
  background-color: $primary;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, .12);
  color: $primary;
  height: $mobile-header-height;
  left: 0;
  text-align: center;
  padding: 1.4rem;
  position: fixed; // it's not clear, but i prefer it like that
  top: 0;
  width: 100%;
  z-index: 1004;

  &__title {
    margin: 0;
  }

  &__logo {
    background-image: url('/images/logo_sidebar.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 173px 54px;
    display: block;
    height: 36px;
  }

  &__nav {
    align-items: center;
    display: flex;
    padding: 0;
    margin: 0;

    > li {
      margin: 0 0.1rem;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      > a {
        color: $white;
        display: block;
        .mdi {
          font-size: 0.2rem;
        }

        &:hover {
          color: lighten($white, 10%);
          text-decoration: none;
        }
      }
    }

    > .divider {
      background-color: #d8d8d8;
      height: $header-height;
      margin: 0 0.2rem;
      opacity: 0.2;
      width: 1px;
    }
  }

  &__dropdown {
    margin: 0 !important;

    .dropdown__toggle {
      background-color: transparent;
      border: 0;
      color: $white;
      font-size: 16px;
      font-weight: 400;
      height: auto;
      line-height: 1;
      text-align: left;
      outline: 0;
      padding: 0;

      .mdi {
        font-size: 0.2rem;
        margin: 0;
      }
    }

    .dropdown__menu {
      left: auto;
      min-width: 220px;
      padding: 8px 12px;
      right: -20px;

      a {
        color: $blue-dark;
        font-weight: $font-weight-semibold;
        text-align: center;
      }
    }
  }
}
