$md-radio-checked-color: $blue;
$md-radio-border-color: rgba(0, 0, 0, 0.54);
$md-radio-size: 20px;
$md-radio-checked-size: 10px;
$md-radio-ripple-size: 15px;

@keyframes ripple {
  0% {
    box-shadow: 0px 0px 0px 1px rgba($md-radio-checked-color, 0);
  }
  50% {
    box-shadow: 0px 0px 0px $md-radio-ripple-size rgba($md-radio-checked-color, 0.1);
  }
  100% {
    box-shadow: 0px 0px 0px $md-radio-ripple-size rgba($md-radio-checked-color, 0);
  }
}

.md-radio {
  &.md-radio-inline {
    display: inline-block;
  }

  input[type='radio'] {
    display: none;
    &:checked + label:before {
      border-color: $md-radio-checked-color;
      animation: ripple 0.2s linear forwards;
    }
    &:checked + label:after {
      transform: scale(1);
    }
  }

  label {
    display: inline-block;
    height: auto;
    line-height: 1.7;
    position: relative;
    padding: 0 ($md-radio-size + 10px);
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: bottom;
    &:before,
    &:after {
      position: absolute;
      content: '';
      border-radius: 50%;
      transition: all 0.3s ease;
      transition-property: transform, border-color;
    }
    &:before {
      left: 7px;
      top: 3px;
      width: $md-radio-size;
      height: $md-radio-size;
      border: 2px solid $md-radio-border-color;
    }
    &:after {
      top: $md-radio-size / 2 - $md-radio-checked-size / 2 + 3;
      left: $md-radio-size / 2 - $md-radio-checked-size / 2 + 7;
      width: $md-radio-checked-size;
      height: $md-radio-checked-size;
      transform: scale(0);
      background: $md-radio-checked-color;
    }
  }
}
