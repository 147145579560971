.status-progress {
    display: flex;

    &--step-wrapper {
        padding: 5px;

        .status-progress--step {
            border-radius: 5px;
            height: 8px;
            background-color: $gray-lighter;

            &-full {
                background-color: $primary !important;
            }
        }
    }




}
