@import '~react-date-range/dist/styles.css'; // main style file
@import '~react-date-range/dist/theme/default.css'; // theme css file

.calendar--icon {
    color: $blue;
    font-size: 1.71428571rem;
    position: absolute;
    right: 20px;
    z-index: 0;
}

.dateRange {

    padding: 0px !important;

    height: 0px;

    border: none !important;

    .rdrDateDisplayWrapper {

        .rdrDateDisplay {
            background-color: transparent;
            padding: 0px;
            justify-content: normal;
        }

        display: none !important;

        .rdrDateDisplayItem {
            flex: none;
            width: 15%;
            input {
                color: #383838;
            }
            border-radius: 0;
            background-color: transparent;
            border: none;
            box-shadow: none;
        }
    }

    .rdrWeekDays {
        .rdrWeekDay:first-child,
        .rdrWeekDay:last-child {
            color: red !important;
        }
        .rdrWeekDay {
            color: $gray-medium;
            font-weight: 500;
        }
    }


    .rdrInRange {
        color: #E1F0F8 !important;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        right: 0px;
        left: 0px;
    }

    .rdrDayNumber span {
        font-weight: 500;
    }

    .rdrDay.rdrDayEndOfWeek .rdrDayNumber span,
    .rdrDay.rdrDayStartOfWeek .rdrDayNumber span {
        color: $secondary !important;
    }

    .rdrDay:not(.rdrDayPassive):not(.rdrDayEndOfWeek):not(.rdrDayStartOfWeek) .rdrInRange~.rdrDayNumber span {
        color: $blue !important;
    }

    .rdrDayToday .rdrDayNumber span:after {
        width: 4px !important;
        height: 4px !important;
    }

    .rdrDayToday .rdrInRange~.rdrDayNumber span:after {
        background: $blue !important;
    }

    .rdrDayToday .rdrStartEdge~.rdrDayNumber span:after,
    .rdrDayToday .rdrEndEdge~.rdrDayNumber span:after {
        background: #fff !important;
    }

    .rdrStartEdge,
    .rdrEndEdge {
        color: $blue !important;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-top-left-radius: 0px;
        top: 9%;
        height: 35px;
        border-bottom-left-radius: 0px;
        right: 0px;
        left: 0px;
    }

    .rdrMonths.rdrMonthsVertical {
        border: 0;
        color: $gray-medium;
        margin: 0;
        width: 100%;
        margin-top: 0px;
        background: #fff;
        box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
        .rdrMonth {
            width: 100%;
            background: #fff;
            z-index: 10;
        }
    }

    .rdrMonthAndYearWrapper {
        box-shadow: 0px -0.13rem 3px rgba(0, 0, 0, 0.1)
    }

    &--none {
        .rdrMonthAndYearWrapper {
            display: none !important;
        }

        .rdrMonths.rdrMonthsVertical {
            display: none !important;
        }
    }

}
