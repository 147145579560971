.login-container {
  background-image: url('/images/background.svg');
  height: 100%;
  min-height: 100vh;
  @media (max-width: 1060px) {
    padding: 100px 5%;
  }
  @media (min-width: 1061px) {
    padding: 100px 35%;
  }
  text-align: center;
  &__content {
    background-color: white;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.24);
    border-radius: 12px;
    display: inline-block;
    margin: 0 auto;
    @media (max-width: 600px) {
      width: 90%;
    }
    @media (min-width: 601px) and (max-width: 1060px) {
      width: 75%;
    }
    @media (min-width: 1061px) {
      width: 45%;
    }
    overflow: hidden;
    position: relative;
    text-align: left;
  }
  &__body {
    background-color: white;
    text-align: left;
    border-radius: 5px;
    padding: 3rem;
    padding-top: 0px;
    border: 1px solid $gray-light;
  }
  &__header {
    background-color: white;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    height: 130px;
    overflow: hidden;
    position: relative;
    text-align: center;
    z-index: 1;
  }
  &__logo {
    background-image: url('/images/logo.svg');
    background-position: center center;
    background-repeat: no-repeat;
    height: 130px;
    margin: auto;
    &:before {
      position: absolute;
      z-index: -1;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 130px;
      margin: 0 auto;
      opacity: 0.3;
      content: '';
      background-image: radial-gradient(circle, white 0%, white 100%);
    }
  }
}

.welcome {
  &__content {
    background-color: white;
    border-radius: 12px;
    display: inline-block;
    margin: 0 auto;
    min-width: 632px;
    width: 75%;
    overflow: hidden;
    position: relative;
    text-align: left;
  }

  &__body {
    padding: 25px 50px 50px 50px;
  }
  &__logo {
    background-image: url('/images/New-password.png');
    background-position: top;
    background-repeat: no-repeat;
    height: 100%;
    margin: auto;
    &:before {
      position: absolute;
      z-index: -1;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 130px;
      margin: 0 auto;
      opacity: 0.3;
      content: '';
      background-image: radial-gradient(circle, white 0%, white 100%);
    }
  }
}
