/* ------------------------------------------------------------ */

/*  Content - Common Layout */

/* ------------------------------------------------------------ */

/* Turn on custom 8px wide scrollbar */
::-webkit-scrollbar {
  width: 10px; /* 1px wider than Lion. */
  height: 12px;
  /* This is more usable for users trying to click it. */
  background-color: rgba(0, 0, 0, 0);
  border-radius: 100px;
}
/* hover effect for both scrollbar area, and scrollbar 'thumb' */
::-webkit-scrollbar:hover {
  background-color: rgba(0, 0, 0, 0.07);
}

/* The scrollbar 'thumb' ...that marque oval shape in a scrollbar */
::-webkit-scrollbar-thumb {
  /* This is the EXACT color of Mac OS scrollbars.
     Yes, I pulled out digital color meter */
  background: rgba(0, 0, 0, 0.4);
  border-radius: 100px;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 2px solid rgba(0, 0, 0, 0);
  min-height: 10px; /*Prevent it from getting too small */
}
::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, 0.6); /* Some darker color when you click it */
  border-radius: 100px;
}

body {
    overflow-x: hidden;
}

html,
body {
    height: 100%;
    width: 100%;
    background-color: #f7f7f7;
    color: #212121;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    height: 100vh;
    margin: 0;
}

.layout {
    width: 100%;
    height: 100%;
}

.react-pdf__Page__canvas {
    margin: auto;
}

h2 {
    font-size: 40px;
}

a {
    color: $blue;
}

.custom-alert {
    border-radius: 5px;
    display: flex;
    padding: 5px;
    margin: 30px;
    margin-top: 10%;
    color: white;
}

@media (min-width: 601px) {
    .layout-main {
        height: 100%;
        //padding-left: 241px ;
        transition: padding-left 160ms ease-in-out;

        .sidebar-collapsed & {
            padding-left: 56px !important;
        }

        &__no-sidebar {
            padding-right: 20rem;
            padding-left: 20rem;
        }
    }
}

@media (max-width: 600px) {
    .layout-main {
        height: 100%;
        padding-top: 91px;
    }
}

// Media Print
@media only print {

    html,
    body {
        min-width: 26cm;
    }

    .layout-header,
    .sidebar {
        display: none !important;
    }

    .layout-main {
        padding: 0 !important;
    }
}

.layout-body {
    // background-color: rgba(250, 250, 250, 1);
    padding-bottom: 30px;

    @media (max-width: 600px) {
        &__header {
            padding: 0.7rem;
        }
    }

    @media (min-width: 601px) and (max-width: 1060px) {
        &__header {
            padding: 1rem;
        }
    }

    @media (min-width: 1061px) {
        &__header {
            padding: 2.5rem;
        }
    }

    &__footer {
        min-height: 80px;
        padding-bottom: 50px;
        padding-left: 45px;
        padding-right: 45px;
        padding-top: 20px;
    }

    &__mid {
        padding-left: 45px;
        padding-right: 45px;
        padding-top: 30px;

        .row {
            margin-left: -7px;
            margin-right: -7px;
        }

        div[class^='col-'],
        div[class*=' col-'] {
            padding-left: 7px;
            padding-right: 7px;
        }

        &--variant {
            .row {
                margin-left: -7px;
                margin-right: -7px;
            }

            div[class^='col-'],
            div[class*=' col-'] {
                padding-left: 7px;
                padding-right: 7px;
            }
        }
    }

    &__content {
        @media (max-width: 600px) {
            padding: 0 0.7rem 0;
        }

        @media (min-width: 601px) and (max-width: 1060px) {
            padding: 0 0.7rem 0;
        }

        @media (min-width: 1061px) {
            padding: 0 2.5rem 0;
        }

        &--variant {
            padding-left: 0;
            padding-right: 0;
        }

        &--gray-background {
            background-color: rgba(250, 250, 250, 1);
        }

        &--white-background {
            background-color: rgba(255, 255, 255, 1);
        }

        &--negative-margin {
            margin-left: -45px;
            margin-right: -45px;
        }

        &--border-bottom {
            border-bottom: 1px solid rgba(0, 0, 0, 0.16);
        }

        &--border-top {
            border-top: 1px solid rgba(0, 0, 0, 0.16);
        }
    }

    // Pagination styles is in its own file
    // Modifiers
    &--creation-form {
        .layout-body__header {
            padding-top: 29px;
        }
    }
}

.subtitle-1 {
    font-size: 26px;
    line-height: 32px;
}

.layout-body__subtitle {
    border-bottom: 1px solid rgba(0, 0, 0, 0.24);
    padding-bottom: 8px;
    margin-top: 26px;
    margin-bottom: 24px;

    .subtitle {
        display: inline-block;
        margin: 2px 8px 4px 0;
        vertical-align: middle;
    }

    .description {
        display: inline-block;
        font-size: 1.4rem;
        vertical-align: middle;
    }

    .btn {
        height: auto;
        min-width: 100px;
    }

    &--variant {
        margin-bottom: 0;
    }

    &--less-margin {
        margin-bottom: 12px;
        margin-top: 18px;
    }
}

.layout-profile {
    position: relative;

    &__aside {
        left: 0;
        position: absolute;
        text-align: center;
        top: 0;
        width: 200px;
    }

    &__picture {
        margin-bottom: 12px;
    }

    &__thumbnail {
        background-color: rgba(0, 0, 0, 0.05);
        background-position: center center;
        background-size: cover;
        height: 200px;
        width: 200px;
    }

    &__pointer {
        cursor: pointer;
    }

    &__content {
        padding-left: 200px;
    }
}

.has-success {
    .help-block {
        color: green;
    }
}

.has-error {
    .help-block {
        color: red;
    }
}


.md-switch.react-toggle.react-toggle--checked .react-toggle-track {
    background-color: $blue;
    border-radius: 5px;
    height: 1rem;
    width: 2.5rem;
}

.md-switch.react-toggle:hover .react-toggle-track {
    background-color: #949ca1;
}

.md-switch.react-toggle .react-toggle-track {
    background-color: #949ca1;
    border-radius: 5px;
    height: 1rem;
    width: 2.5rem;
}

.md-switch .react-toggle-track {
    height: 1rem;
    width: 2.5rem;
}

.md-switch.react-toggle .react-toggle-thumb {
    left: 0;
}

.md-switch.react-toggle.react-toggle--checked .react-toggle-thumb {
    left: calc(100% - 18px);
}

.md-switch.react-toggle .react-toggle-thumb,
.md-switch.react-toggle.react-toggle--checked .react-toggle-thumb {
    background-color: $white;
    border: none;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14),
        0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    height: 20px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
}

.md-switch.react-toggle.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background: $blue;
}

.rightMenuMobile{
    color:#fff;
    background-color: #124B6F;
}