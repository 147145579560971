@import '~rc-slider/assets/index.css';
@import '~rc-tooltip/assets/bootstrap.css';

.financing-slider {
    margin: auto;
    width: 100%;

    .rc-slider {
        margin-bottom: 26px;
    }
}

.tached {
    text-decoration: line-through;
}

.slider-tooltip {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
    z-index: 9999;
    font-size: 16px;
    line-height: 1.5;
    opacity: 0.9;
    user-select: none;
    visibility: visible;
    opacity: 0;
    transition: all ease-in-out 150ms;

    &.active {
        visibility: visible;
        opacity: 1;
        top: -10px;
    }

    span {
        display: block;
        text-align: center;
        color: $blue;
        text-decoration: none;
        background-color: $white;
        border-radius: 5px;
        box-shadow: 0 0 4px $blue;
        position: relative;
        font-weight: bold;
        user-select: none;
        padding: 5px 10px;

        &:after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border-color: transparent;
            border-style: solid;
            border-width: 5px 5px 0;
            border-top-color: $blue;
            left: 50%;
            bottom: 0;
            transform: translate(-50%, 100%);
        }
    }
}
