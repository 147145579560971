@import '~rc-steps/assets/index.css';

.financing-action-list {
    .rc-steps-item-active,
    .rc-steps-item-wait,
    .rc-steps-item-finish {
        .rc-steps-item-icon {
            .rc-steps-icon {
                color: $primary !important;
                font-size: 40px;
            }
        }

        .rc-steps-item-content {
            margin-top: 9px !important;

            .rc-steps-item-title {
                color: rgba(0, 0, 0, 0.65) !important;
            }
        }

        .rc-steps-item-tail {
            left: 19px !important;
            padding: 46px 0 4px 0 !important;
        }
    }

}

.form-stepper {
    background-color: $white !important;
    .rc-steps-item-active,
    .rc-steps-item-finish {
        .rc-steps-item-icon {
            background: $primary !important;

            .rc-steps-icon {
                color: $white !important;
            }
        }
    }


    @media (min-width: 601px) {
        .rc-steps-item-icon {
            width: 41px;
            height: 41px;
            line-height: 41px;
            margin-left: 30px !important;

            .rc-steps-icon {
                font-size: 20px;
                line-height: inherit;
                font-weight: bold;
            }
        }
    }

    .rc-steps-item-wait {
        .rc-steps-item-icon {
            background: $white !important;

            border-color: $primary !important;

            .rc-steps-icon {
                color: $primary !important;
            }
        }
    }

    @media (min-width: 601px) {
        .rc-steps-item-tail {
            padding: 0px 30px !important;
            margin-left: 50px !important;
            margin-top: 7px !important;
        }
    }
}


.mobile-stepper {
    background-color: $white !important;
    .rc-steps-horizontal {
        .rc-steps-item {

            @media (max-width: 600px) {
                .rc-steps-item-tail {
                    padding: 0px;
                    margin-left: 50% !important;
                    top: 12px;
                    width: 33%;
                }
            }

            @media (max-width: 1060px) and (min-width: 601px) {
                .rc-steps-item-tail {
                    padding: 0px;
                    margin-left: 68%;
                    top: 12px;
                    width: 37%;
                }
            }
        }
    }

    .rc-steps-item {
        width: 14% !important;

        .rc-steps-item-icon {
            margin-left: 24%;
        }

        @media (max-width: 600px) {
            .rc-steps-item-tail {
                padding: 0px;
                margin-left: 65%;
                top: 12px;
                width: 33%;
            }
        }

        @media (max-width: 1060px) and (min-width: 601px) {
            .rc-steps-item-tail {
                padding: 0px;
                margin-left: 68%;
                top: 12px;
                width: 37%;
            }
        }

        .rc-steps-item-content {
            width: 66%;

            .rc-steps-item-title {
                font-size: 10px;
            }
        }
    }
}

.rc-steps-item-content {
    margin-top: -2px !important;
}

.rc-steps-small {
    .rc-steps-item-title {
        font-weight: inherit;
    }
}
.rc-steps-item-custom.rc-steps-item-process .rc-steps-item-icon>.rc-steps-icon {
    color: $primary;
}

.rc-steps-item-title {
    font-weight: inherit;
}

.rc-steps-vertical {
    .rc-steps-item-content {
        min-height: auto;
    }
}

.rc-steps-horizontal {
    .rc-steps-label-vertical .rc-steps-item-icon {
        display: inline-block;
        margin-left: 40px;
    }
}

