.cart {
    background-color: $white;
    @media (max-width: 600px) {
        padding: 15px 0px;
        .cart-items {
            font-size: 14px;
        }
    }
    @media (min-width: 601px) {
        padding: 15px 16px;
    }
    strong {
        color: $headings-color;
    }

    .cart-items {
        width: 100%;
        border-bottom: 1px solid #dee2e6;

        tr {
            height: 40px;
            // border-bottom: 1px solid $gray-shadow;
        }

        .product {
            overflow-x: hidden;
            padding: 0;
            width: 100%;
            list-style: none;
        }

        .product:before {
            float: left;
            width: 0;
            white-space: nowrap;
            content: ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
        }

        span:first-child {
            padding-right: 0.33em;
            background-color: $white;
        }

        span+span {
            float: right;
            padding-left: 0.33em;
            background-color: $white;
        }

        > thead > tr > th {
            height: 50px;
            color: $gray;
            font-weight: 400;
            padding-right: 15px;
        }
    }

    .totals {
        @media (max-width: 600px) {
            font-size: 15px;
        }
        font-size: 16px;
        padding: 0;
        margin-top: 20px;
        overflow-x: hidden;
        list-style: none;

        li:before {
            float: left;
            width: 0;
            white-space: nowrap;
            content: ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ."
        }

        span:first-child {
            padding-right: 0.33em;
            background-color: $white;
        }

        span+span {
            float: right;
            padding-left: 0.33em;
            background-color: $white;
        }
    }
}
