.toggle-password {
    color: $blue-dark;
    cursor: pointer;
    opacity: 0.50;
    z-index: 3;
    font-size: 1.71428571rem;
    position: absolute;
    right: 6px;
    top: 70%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);

    &--active {
        opacity: 1;
    }
}
