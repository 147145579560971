// Dropdown component
.dropdown {
  $parent: &;
  display: inline-block;
  &__toggle {
    outline: 0;
  }
  &__menu {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 0 4px 5px 0 rgba(0, 0, 0, 0.12), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
      0 2px 4px -1px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    color: $gray-darker;
    display: none;
    float: left;
    font-size: 1.4rem;
    left: 0;
    list-style: none;
    margin: 0;
    min-width: 180px;
    padding: px-to-rem(16);
    position: absolute;
    text-align: left;
    top: 48px;
    z-index: 1000;

    .dt & {
      min-width: 130px;
      top: 38px;
    }
    > ul {
      margin: 0;
      padding: 0;
      > li {
        display: block;
        margin: 0;
        margin-bottom: 0;
        width: 100%;

        &:not(:last-child) {
          margin-bottom: 1rem;
        }
      }
    }
    .divider {
      background-color: $gray-lighter;
      height: 1px;
      margin: 2px 0;
      width: 100%;
    }
    .open & {
      display: block;
    }
  }
  &__item {
    background-color: transparent;
    border: 0;
    display: block;
    color: $green-dark;
    font-size: 16px;
    padding: 7px 20px;
    width: 100%;
    .dt & {
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      padding: 10px 0;
      text-align: left;
    }
    .dt #{$parent}__list li:last-child > & {
      border-bottom: 0;
    }
  }
  &__arrow {
    background-color: transparent;
    top: -14px;
    display: block;
    height: 14px;
    left: 15px;
    overflow: hidden;
    position: absolute;
    right: 0;
    width: 28px;
    z-index: 1;
    &::before {
      background-color: white;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
      content: '';
      display: block;
      height: 28px;
      left: 50%;
      margin-left: -14px;
      position: absolute;
      top: 8px;
      transform: rotate(45deg);
      width: 28px;
    }
  } // Modifiers
  &--right {
    #{$parent}__menu {
      left: auto;
      right: 0;
    }
    #{$parent}__arrow {
      left: auto;
      right: 15px;
    }
  }
  &--center {
    #{$parent}__menu {
      left: 50%;
      right: auto;
      transform: translateX(-50%);
    }
    #{$parent}__arrow {
      left: 50%;
      right: auto;
      transform: translateX(-50%);
    }
  }
}
