.dropzone {
  $parent: &;
  background-color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
  padding: 19px 15px 15px 15px;

  &-delete-icon {
    position: absolute;
    left: 28%;
    bottom: 50px;
    font-size: 20px !important;
    color: #007dc3;
  }

  &__realestate {
    border: 1px dashed $gray !important;
    cursor: pointer !important;
    display: block !important;
    min-height: 205px;
    align-items: inherit !important;
    padding: 5px !important;
    margin: 0 !important;
    justify-content: initial !important;

    &-mobile {
      min-height: auto !important;
      border: 1px dashed $gray !important;
      cursor: pointer !important;
      display: block !important;
      align-items: inherit !important;
      padding: 5px !important;
      margin: 0 !important;
      justify-content: initial !important;
    }
  }

  &__instance {
    align-items: center;
    border: 1px dashed $gray;
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    padding: 45px 0;

    input {
      width: inherit;
    }

    .btn {
      z-index: 10;
      margin-bottom: px-to-rem(1);
    }

    &--active {
      background-color: rgba(41, 182, 246, 0.1);
      border: 1px solid $blue-light;
    }

    &--selected {
      border: none;
      border-radius: 50%;
      height: 180px;
      margin-bottom: 2.75rem;
      padding: 0;
      width: 180px;

      pointer-events: none;

      a, button {
        pointer-events: all;
      }

      &--full {
        height: 100%;
        width: 100%;
      }

      .dropzone__message {
        width: 100%;
        height: 100%;

        *:not(.btn) {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
  &__message {
    text-align: center;
    span,
    p {
      font-size: 14px;
    }
  }
  &__image-preview {
    background-color: transparent;
    background-position: center center;
    background-size: cover;
    display: inline-block;
    border-radius: 4px;
    margin-right: 10px;
    &__xs {
      height: 50px;
      width: 50px;
    }
    &__sm {
      height: 70px;
      width: 70px;
    }
    &__md {
      height: 100px;
      width: 100px;
    }
    &__lg {
      height: 130px;
      width: 130px;
    }
    &__xl {
      height: 180px;
      width: 180px;
    }
    &__full {
      height: 100%;
      width: 100%;
    }
  }
  .slick-active {
    #{$parent}__image-preview {
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
    }
  }
  .slick-dots {
    bottom: 0;
    position: relative;
    text-align: left;
    top: 0;
    li {
      display: inline-block;
      height: auto;
      margin: 0;
      width: auto;
    }
  }
  .slick-track {
    #{$parent}__image-preview {
      height: 290px;
      margin: 10px 0 5px;
      width: 100%;
    }
  }
  .dropzone__instance--selected.dropzone__instance--single {
    min-height: 100%;

    #{$parent}__message {
      padding: 12px 35px 14px;
    }
    & + .dropzone__image-preview {
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
      height: 280px;
      width: 100%;
    }
  }
  &__instance--smaller {
    min-height: 150px;
    #{$parent}__message {
      padding: 38px 15px 20px;
    }
  }
}
