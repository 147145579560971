.user-detail-box {
    border: 1px solid #dee2e6;
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    overflow-y: auto;

    @media (max-width: 440px) {
        width: 99%;
    }
}

.product-detail-box {
    border: 1px solid #dee2e6;
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    overflow-y: auto;
    max-height: 377px;

    @media (max-width: 440px) {
        width: 99%;
    }
}

.user-logs-box {
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid #dee2e6;
    border-radius: 5px;
    max-height: 420px;
    overflow-y: auto;

    @media (max-width: 440px) {
        width: 99%;
    }
}
