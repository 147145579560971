.transaction-checkout {
    border: 1px solid $gray-light;
    border-radius: 5px;

    &-wrapper {
        border: 0px;

        @media (min-width: 601px) {
            margin: 0 8rem 0;
        }
    }

    @media (max-width: 440px) {
        padding: 10px;
        .wdivImg {
            width: 45% !important;
            text-align: center;
        } 
    }
    @media (max-width: 600px) {
        padding: 10px;
        .wdivImg {
            width: 45% !important;
            text-align: center;
        } 
    }

    @media (min-width: 601px) {
        padding: 40px;
        .wdivImg {
            width: 45% !important;
            text-align: center;
        } 
    }
}

.Plantable {
    white-space: nowrap;
    margin: 0;
    border-collapse: separate;
    border-spacing: 0;
    table-layout: fixed;
    border: 1px solid #d2d2d2;
  }
.Plantable td,
.Plantable th {
    border-bottom: 1px solid #d2d2d2;
    box-shadow:'0 1px 5px 0 rgba(0, 0, 0, 0.15)';
}
.Plantable thead th {
    position: sticky;
    top: 0;
    z-index: 1;
    background: white;
}
.Plantable td {
    background: #fff;
    //padding: 4px 5px;
    text-align: center;
}
  
.Plantable tbody th {
    text-align: left;
    position: relative;
}
.Plantable thead th:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
   // width: 60px;
    word-wrap: break-word;
    border-left: 2px solid #d2d2d2;
}

.Plantable tbody th {
    position: sticky;
    left: 0;
    background: white;
    z-index: 1;
    border-left: 2px solid #d2d2d2;
}

  [role="region"][aria-labelledby][tabindex] {
    width: 100%;
    max-height: 98vh;
    overflow: auto;
  }
  [role="region"][aria-labelledby][tabindex]:focus {
    box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);
    outline: 0;
  }

  .selectEntity {
      z-index: 3;
  }
