.dt {
  @extend %dt;
  .rt-table {
    @extend %dt__table;
  }
  .rt-thead.-header {
    min-width: max-content !important;
    @extend %dt__head;
    .rt-tr {
      @extend %dt__head__row;
    }
    .rt-resizable-header-content {
      @extend %dt__head__resizable;
    }
    .rt-th {
      @extend %dt__head__data;
      &.-sort-desc {
        @extend %dt__head__data--sort-desc;
      }
      &.-sort-asc {
        @extend %dt__head__data--sort-asc;
      }
      .dropdown__toggle {
        @extend %dt__head__dropdown__button;
      }
      .mdi {
        @extend %dt__head__data--sort-icon;
      }
    }
  }
  .rt-tbody {
    min-width: max-content !important;
    @extend %dt__body;
    .rt-tr-group {
      @extend %dt__body__row-group;
    }
    .rt-tr {
      @extend %dt__body__row;
    }
    .rt-tr.-featured {
      @extend %dt__body__row--featured;
    }
    .rt-tr.-read {
      @extend %dt__body__row--read;
    }
    .rt-td {
      @extend %dt__body__data;
      .dt__actions {
        @extend %dt__actions;
      }
    }
    .rt-td--checkbox {
      min-height: 24px;
    }

    .rt-td--actions {
      overflow: unset !important;
    }
  }
  .rt-noData {
    @extend %dt__no-data;
  }
}

.dt-printable {
  height: max-content;
}

.dt-plans {

  .rt-tr {
    padding: px-to-rem(8) !important;
  }

  .rt-thead.-header {
    padding: 0px !important;
  }

  .rt-tbody,
  .rt-thead {
    overflow: visible;
    width: auto;
  }
  .rt-tr-group {
    flex-direction: unset !important;
    padding-left: px-to-rem(8) !important;
    padding-right: px-to-rem(8) !important;
  }
  .rt-td,
  .rt-th {
    margin: 0px 10px !important;
    width: 150px;
    min-width: 150px;
    text-overflow: ellipsis;

    &:first-child {
        width: 50px !important;
        min-width: 50px !important;
    }
  }
}

.dt-plans-matrix {

  .rt-tr {
    padding: px-to-rem(8) !important;
  }

  .rt-thead.-header {
    padding: 0px !important;
  }

  .rt-tbody,
  .rt-thead {
    overflow: visible;
    width: auto;
  }
  .rt-tr-group {
    flex-direction: unset !important;
    padding-left: px-to-rem(8) !important;
    padding-right: px-to-rem(8) !important;
  }
  .rt-td,
  .rt-th {
    margin: 0px 10px !important;
    width: 150px;
    min-width: 150px;
    text-overflow: ellipsis;
  }
}


.dt-users {
  .rt-tbody {
    overflow: inherit !important;
  }
}

// DataTable
%dt {
  background-color: transparent;
  border: none;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
}

%dt__table {
  background-color: transparent;
  overflow: auto;
}

// DataTable Head
%dt__head {
  background-color: rgba(249, 249, 249, 1);
  box-shadow: inset 0 -1px 0 0 #f3f3f3;
  padding: 0;
  padding: 0 px-to-rem(16);
}

%dt__head__row {
  align-items: center;
  display: flex;
  background-color: transparent;
}

%dt__head__data {
  background-color: transparent;
  border: 0;
  box-shadow: inset -1px 0 0 0 #f3f3f3;
  font-weight: 500;
  height: 64px;
  // max-width: 127px;
  overflow: visible;
  padding: 0;
  text-align: left;

  div {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    padding: 1rem px-to-rem(8);
  }

  &:last-child {
    box-shadow: none;
  }

  &:first-child {
    text-align: left;
  }
}

.dt__header-label {
    font-weight: bold;
}

.dt__header-head {
    padding: 1rem;
  white-space: pre-wrap;
  word-break: break-word;
}

%dt__head__dropdown__button {
  background-color: transparent;
  border-color: transparent;
  color: $blue-dark;
  font-weight: 500;
  padding: 0;
}

%dt__head__data--sort-desc {
  .dt__header-icon {
    color: $blue !important;
  }
  // box-shadow: inset 0 -3px 0 0 $blue-dark;
}

%dt__head__data--sort-asc {
  .dt__header-icon {
    color: $blue !important;
  }
  // box-shadow: inset 0 3px 0 0 $blue-dark;
}

%dt__head__data--sort-icon {
  color: #989898;
  font-size: px-to-rem(16);
  vertical-align: middle;
}

// DataTable Body
%dt__body {
  list-style: none; // min-height: 200px;
}

%dt__body__row-group {
  border: 0;
  min-height: 50px;
  &:hover {
    background-color: #fafafa;
  }
}

%dt__body__row {
  align-items: center;
  box-shadow: inset 0 -1px 0 0 #f3f3f3;
  display: flex;
  cursor: pointer;
  padding: 0;
  padding: px-to-rem(8) px-to-rem(24) px-to-rem(8);
}

%dt__body__data {
  border: 0;
  font-weight: 500;
  padding: 1rem;
  overflow: hidden;
  text-align: left;
  &:first-child {
    margin-left: 0;
    text-align: left;
  }
}

%dt__foot {
  list-style: none;
}

%dt__actions {
  .btn {
    margin: 0 5px;
    height: auto;
    padding: 2px;
    .mdi-pencil {
      font-size: 2.2rem;
    }
  }
}

%dt__no-data {
  background-color: transparent;
}

// Modifiers
%dt__body__row--featured {
  background-color: $blue-dark;
  font-weight: $font-weight-bold;
  padding-top: 35px;
  position: relative;
  &::before {
    display: block;
    color: $gray-dark;
    content: 'Featured';
    font-weight: $font-weight-regular;
    height: 35px;
    left: 0;
    position: absolute;
    padding: 8px (45px + 8px) 5px;
    top: 0;
    width: 100%;
  }
}

// Modifiers
%dt__body__row--read {
  background-color: $blue-dark;
}

%dt__head__resizable {
  overflow: visible;
}
.data-table__image.stat-card__thumbnail {
  width: 40px;
  height: 40px;
}

.ReactTable.dt,
.ReactTable.dt .rt-thead {
  background: $white;
}
