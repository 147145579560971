// Sidebar
.sidebar {
  $parent: &;
  background-color: $primary;
  bottom: 0;
  box-shadow: 0 4px 5px 0 rgba(0,0,0,0.12);
  height: 100%;
  left: 0;
  overflow: hidden;
  overflow-y: hidden;
  padding-top: 40px;
  position: fixed;
  top: 0;
  transition: width 160ms ease-in-out;
  //width: 256px;
  z-index: 98;
  &:hover {
    overflow-y: auto;
  }
  &__footer {
    bottom: 0px;
    //width: 256px;
    z-index: 100;
    position: fixed;
  }
  &__title {
    left: 50%;
    line-height: 20px;
    margin: 0;
    position: absolute;
    text-align: center;
    top: 40px;
    transform: translate(-50%, 0);
  }
  &__logo {
    background-image: url('/images/logo_sidebar.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 160px 26px;
    display: block;
    margin: 0px 22px 70px 22px;
    height: 46px;
    margin-bottom: 70px;
    transition: width 160ms ease-in-out;
    width: 160px;
  }
  &__list {
    margin: 0;
    padding: 0;
  }
  &__item {
    display: block;
    color: white;
  }
  &__link {
    align-items: center;
    background-color: $primary;
    color: $white;
    display: flex;
    font-weight: 500;
    padding: 1rem;
    padding-left: 1.5rem;
    transition: background-color 160ms ease-in-out;
    width: 100%;
    will-change: background-color, padding;

    &:hover,
    &:focus,
    &:active:focus {
        color: $white;
        background-color: $green-transparent !important;
        text-decoration: none;
    }
  }
  button {
    align-items: center;
    background-color: $white;
    color: $green-dark;
    display: flex;
    font-weight: 500;
    padding: 1rem;
    padding-left: 1.5rem;
    transition: background-color 160ms ease-in-out;
    width: 100%;
    will-change: background-color, padding;

    &:hover,
    &:focus:hover,
    &:active:focus:hover {
      background-color: $green-transparent !important;
      text-decoration: none;
    }
    &:focus,
    &:active:focus {
      background-color: $white !important;
    }
  }
  &__link__icon {
    font-size: 1.5rem;
    margin-right: 1rem;
    transition: transform 160ms ease-in-out;
  }
  &__link--active {
    background-color: $green-transparent;
  }
  &__link--http {
    &:focus,
    &:active:focus {
      background-color: $white !important;
    }
    &:hover {
      background-color: $green-transparent !important;
    }
  }// Toggle Button
  &__toggle-button {
    @extend .sidebar__link;
    justify-content: flex-start;
    border-top: 1px solid #C4C4C4;
    background-color: $white !important;
    &:hover {
      #{$parent}__toggle-button__icon {
        transform: rotateY(0deg) scale(1.2);
      }
    }
  }
  &__toggle-button__icon {
    font-size: 2rem !important; // Its visually smaller
    margin: 0;
    margin-right: 0;
    transform: rotateY(0deg) scale(1);
    transition: transform 160ms ease-in-out;
    will-change: transform;
  } // Modifiers
  .sidebar-collapsed & {
    width: 56px;
    overflow: hidden;
    #{$parent}__logo {
      width: 38px;
    }
    #{$parent}__link {
      padding-right: 0;
    }
    // Toggle Button
    #{$parent}__toggle-button__icon {
      transform: rotateY(180deg) scale(1);
    }
    #{$parent}__toggle-button:hover {
      #{$parent}__toggle-button__icon {
        transform: rotateY(180deg) scale(1.2);
      }
    }
    #{$parent}__toggle-button__text {
      display: none;
    }
  }
}

.sidebar-collapsed .sidebar .sidebar__link {
  padding-left: 1rem;
}

// Sidebar
.sidebar-mobile {
  $parent: &;
  bottom: 0;
  background-color: $primary;
  height: 100%;
  left: 0;
  overflow: hidden;
  overflow-y: hidden;
  position: relative;
  top: 0;
  transition: width 160ms ease-in-out;
  z-index: 98;
  &:hover {
    overflow-y: auto;
  }
  &__title {
    left: 50%;
    line-height: 20px;
    margin: 0;
    position: absolute;
    text-align: center;
    top: 40px;
    transform: translate(-50%, 0);
  }
  &__logo {
    background-image: url('/images/logo.svg');
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 227px 38px;
    display: block;
    height: 38px;
    transition: width 160ms ease-in-out;
    width: 227px;
  }
  &__list {
    margin: 0;
    padding: 0;
  }
  &__item {
    display: block;
    color: white;
  }
  &__link {
    align-items: center;
    background-color: $white;
    color: $blue-dark;
    display: flex;
    padding-left: 15px;
    font-weight: 500;
    padding: px-to-rem(16);
    padding-left: px-to-rem(24);
    transition: background-color 160ms ease-in-out;
    width: 100%;
    will-change: background-color, padding;

    &:hover,
    &:focus,
    &:active:focus {
      background-color: $blue-transparent !important;
      text-decoration: none;
    }
  }
  button {
    align-items: center;
    background-color: $white;
    color: $blue-dark;
    display: flex;
    font-weight: 500;
    padding: px-to-rem(16);
    padding-left: px-to-rem(24);
    transition: background-color 160ms ease-in-out;
    width: 100%;
    will-change: background-color, padding;

    &:hover,
    &:focus:hover,
    &:active:focus:hover {
      background-color: $blue-transparent !important;
      text-decoration: none;
    }
    &:focus,
    &:active:focus {
      background-color: $white !important;
    }
  }
  &__link__icon {
    font-size: px-to-rem(24);
    margin-right: px-to-rem(16);
    transition: transform 160ms ease-in-out;
  }
  &__link--active {
    background-color: $blue-transparent;
  } // Toggle Button
  &__link--http {
    &:focus,
    &:active:focus {
      background-color: $white !important;
    }
    &:hover {
      background-color: $blue-transparent !important;
    }
  } // Toggle Button
  &__toggle-button {
    @extend .sidebar__link;
    justify-content: flex-start;
    border-top: 1px solid #C4C4C4;
    background-color: $white !important;
    &:hover {
      #{$parent}__toggle-button__icon {
        transform: rotateY(0deg) scale(1.2);
      }
    }
  }
  &__toggle-button__icon {
    font-size: px-to-rem(30) !important; // Its visually smaller
    margin: 0;
    margin-right: 0;
    transform: rotateY(0deg) scale(1);
    transition: transform 160ms ease-in-out;
    will-change: transform;
  } // Modifiers
  .sidebar-collapsed & {
    width: 56px;
    overflow: hidden;
    #{$parent}__logo {
      width: 38px;
    }
    #{$parent}__link {
      padding-right: 0;
    }
    // Toggle Button
    #{$parent}__toggle-button__icon {
      transform: rotateY(180deg) scale(1);
    }
    #{$parent}__toggle-button:hover {
      #{$parent}__toggle-button__icon {
        transform: rotateY(180deg) scale(1.2);
      }
    }
    #{$parent}__toggle-button__text {
      display: none;
    }
  }
}

.electrodato {
  color: #fff;
  font-size: 12px;
  margin-top: 70px;
  line-height: 12px;
  padding: 7px 25px;
}
