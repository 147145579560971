.form-label {
  width: 100%;
}

input.form-control {
    min-height: 44px;
}

.form-control {
    background: transparent;
    &:focus {
        outline: none !important;
        // border-color: $blue !important;
        border: 2px solid $blue !important;
    }
}

.input-wrapper.has-error {
//   margin-top: 0px;
}

.input-wrapper {
  margin-bottom: px-to-rem(20);

  & + &:not([class*='col-']) {
    margin-left: px-to-rem(16);
  }

  .form-control {
    background-color: transparent;
    width: 100%;
    &:active,
    &:focus,
    &:hover {
      outline: 0 none;
    }
  }

  .form-text.text-muted {
    color: red !important;
  }

  //This is used to avoid overlapping on components
  &--datepicker {
    position: relative;
    z-index: 10;
  }

  .form-label {
    font-size: 0.8rem;
    font-weight: 500;
    letter-spacing: 0.11px;
    margin-bottom: 0;
    position: relative;
    width: 100%;

    .label {
      display: block;
      margin-bottom: 0.2rem;
    }
  }

  .form-control {
    background-color: transparent;
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 4px;
    color: $text-color;
    font-weight: 500;
    letter-spacing: 0.25px;
    line-height: 15px;
    padding: px-to-rem(8) px-to-rem(4) px-to-rem(8) px-to-rem(12);

    &:focus {
      border-color: rgba($blue-light, 0.4);
      outline: none;
    }
  }
}

.has-error {
  .form-control {
    box-shadow: none;
    color: $gray;
    border: 2px solid $red !important;

    &::placeholder {
      color: $gray;
    }
  }

  .dropzone {
      box-shadow: none;
      color: $gray;
      border: 2px solid $red !important;
  }

  .form-control:focus {
    border: 2px solid $blue !important;
  }
}

.form-label.select-container--with-icon .Select-arrow {
  display: none;
}
